<template>
  <div class="articleDialog">
    <el-dialog
      top="3%"
      width="50%"
      :title="dialogTitle"
      :visible.sync="visible"
      :close-on-click-modal="false"
      :before-close="closeEvent"
    >
      <el-form ref="dialogForm" :model="form" label-width="120px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="文章标题" prop="title">
              <el-input
                v-model="form.title"
                placeholder="请输入文章标题"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="排序" prop="articleSort">
              <el-input
                type="text"
                v-model="form.articleSort"
                placeholder="请输入序号"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="文章内容" prop="content">
              <vue-editor
                useCustomImageHandler
                @image-added="handleImageAdded"
                v-model="form.content"
              ></vue-editor>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeEvent()">取 消</el-button>
        <el-button type="primary" @click="submitData()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import axios from "axios";
export default {
  components: { VueEditor },
  data() {
    return {
      visible: false,
      dialogTitle: "新增文章",
      userList: [],
      form: {
        title: "",
        content: "",
        articleSort: "",
      },
    };
  },
  methods: {
    handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
      var formData = new FormData();
      formData.append("file", file);

      axios({
        url: "/api/upload?group=activeDetails",
        method: "POST",
        data: formData,
        headers: {
          token: localStorage.getItem("token"),
        },
      }).then((result) => {
        const url = result.data.data.path; // Get url from response
        Editor.insertEmbed(cursorLocation, "image", url);
        resetUploader();
      });
    },
    submitData() {
      this.$refs.dialogForm.validate((valid) => {
        if (valid) {
          let params = { ...this.form };
          let url = "";
          if (params.id) {
            //修改
            url = "api/article/update";
          } else {
            url = "api/article/add";
          }

          this.getAjax(url, params, "post").then(() => {
            this.$message.success("操作成功");
            this.closeEvent();
            this.$emit("search");
          });
        }
      });
    },
    closeEvent() {
      this.visible = false;
      this.dialogTitle = "新增文章";
      this.$refs.dialogForm.resetFields();
      this.form = {};
    },
    getInfo(id) {
      this.dialogTitle = "编辑文章";
      this.getAjax("api/article/details?id=" + id, {}, "get").then((res) => {
        this.form = res.data;
        this.visible = true;
      });
    },
  },
  mounted() {
    //获取所有用户
    this.getAjax("api/allUser", {}, "get").then((res) => {
      this.userList = res.data.list.map((e) => {
        return {
          label: e.nickName,
          value: e.id,
        };
      });
    });
  },
};
</script>

<style lang="less">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9 !important;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
